import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueChartkick from 'vue-chartkick'
import 'chartkick/chart.js'
import JsonCSV from 'vue-json-csv'
import excel from 'vue-excel-export'
import CKEditor from '@ckeditor/ckeditor5-vue';


createApp(App).use(VueChartkick).use(JsonCSV).use(excel).use(CKEditor).use(store).use(router).mount('#apps')