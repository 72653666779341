<template>
  <div class="site-body">
    <header>
      <h1>Course</h1>
      <h4>{{ get_email }}</h4>
    </header>
    <div class="course-list">
      <div
        class="card"
        v-for="(item, index) in course_list"
        :key="index"
        @click="navigate_course_datails(item.id)"
      >
        <img class="card-img-top" :src="item.image" alt="Card image cap" />
        <div class="card-body">
          <h5 class="card-title">{{ item.name }}</h5>
        </div>
        <!-- <div class="card-body">
          <a href="#" class="card-link">Card link</a>
          <a href="#" class="card-link">Another link</a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      list: ["a", "b", "c"],
    };
  },
  methods: {
    navigate_course_datails(pk) {
      this.$router.push({ path: `/webadmin/course/${pk}` });
    },
  },
  mounted() {
    this.api_calling = true;
    // var token = localStorage.getItem("token");
    var token = this.token;
    const config = {
      headers: {
        Authorization: "Token " + token,
      },
    };

    var url = `${process.env.VUE_APP_BASE_URL}/course/list`;
    this.$store.commit("update_is_loading", true);
    axios
      .get(url, config)
      .then((response) => {
        console.log(response.data.results);
        this.$store.commit("update_course_list", response.data.results);
      })
      .catch((error) => {})
      .finally(() => {
        this.$store.commit("update_is_loading", false);
      });
  },
  computed: {
    course_list() {
      return this.$store.state.course_list;
    },
    token() {
      var t = localStorage.getItem("admintokenofeducodiv");
      return t;
    },
    get_email() {
      return localStorage.getItem("admintokenofeducodivusername");
    },
  },
};
</script>

<style scoped>
.course-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.card {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin: 1rem;
  width: 25%;
}
</style>