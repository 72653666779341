import {
  createStore
} from 'vuex'

export default createStore({
  getters: {
  //   get_current_page_number (state) {
  //   return state.total_data
  // }
  },
  mutations: {},
  actions: {},
  state: {
    is_loading: false,
    course_list: []
  },
  mutations: {
    update_is_loading(state, data) {
      state.is_loading = data
      console.log("is loading " + data)
    },

    update_course_list(state, data) {
      state.course_list = data
    }
  }
})