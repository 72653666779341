<template>
  <div class="header">
    <div class="title">
      <label for="header-toggle">
        <input type="checkbox" id="header-toggle" onclick="header_action()" />
        <span id="header-toggle-span"></span>
      </label>
      <h1>Educodiv Admin</h1>
    </div>
  </div>
</template>
<style>
.nav-background {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 54, 121, 1) 0%,
    rgba(7, 82, 150, 1) 40%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
}

* {
  margin: 0px;
  padding: 0px;
}

.header {
  display: flex;
  justify-content: space-between;
  background: skyblue;
  align-items: center;
  margin: 0px;
  padding: 3px;
  position: sticky;
  top: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 54, 121, 1) 0%,
    rgba(7, 82, 150, 1) 40%,
    rgba(0, 212, 255, 1) 100%
  ) !important;
  color: white;
}

.header div {
  display: flex;
  margin: 0px;
  padding: 0px;
  align-items: center;
}

.header div h1 {
  margin: 0px;
  padding: 0.1rem;
  color: white;
}

.header ul {
  display: flex;
  list-style: none;
  margin: 0px;
}

.header ul li {
  padding: 1rem;
}

#header-toggle {
  display: none;
}

#header-toggle-span {
  margin: 0px;
}
label {
  margin: 0px;
  padding: 0px;
}
#header-toggle-span::before {
  content: "\f0c9";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1.3rem;
}

#header-toggle:checked + #header-toggle-span::before {
  content: "\f137";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1.3rem;
}

.header .title label {
  display: none;
}

@media only screen and (max-width: 768px) {
  .header .title label {
    display: block;
  }
  .header {
    flex-direction: column;
    justify-content: left;
    align-items: start;
  }
  .header ul {
    flex-direction: column;
  }
}
</style>