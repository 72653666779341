<template>
  <div class="side-bar" id="side-bar">
    <div class="logo">
      <h3><span>Educodiv Admin</span></h3>
    </div>

    <div class="sidebar-menu">
      <a
        href="#"
        class="dashboard"
        :class="[tab === '/' ? 'active' : '']"
        @click="navigate_home"
        ><i class="fa-solid fa-gauge"></i> <span>Course</span></a
      >

      <a href="#" class="dashboard" @click="log_out"
        ><i class="fa-solid fa-right-from-bracket"></i> <span>Log Out</span></a
      >
    </div>
  </div>
</template>








<script>
export default {
  data() {
    return {};
  },
  methods: {
    navigate_home() {
      this.$router.push({
        path: "/webadmin",
      });
    },
    log_out() {
      localStorage.removeItem("admintokenofeducodiv");
      location.reload();
    },
  },

  mounted() {
    console.log(this.$route.path);
  },
  computed: {
    tab() {
      return this.$route.path;
    },
  },
};
</script>





<style scoped>
@media print {
  .sidebar {
    display: none;
  }
}
</style>