<template>
  <div>
    <HeaderComponent />
    <div class="main-content">
      <SidebarComponent />
      <CourseDetailsComponent />
    </div>

    <LoadingComponent v-if="is_loading" />
  </div>
</template>

<script>
import SidebarComponent from "@/components/SidebarComponent.vue";
import CourseDetailsComponent from "@/components/CourseDetailsComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "CourseDetailsView",
  components: {
    SidebarComponent,
    CourseDetailsComponent,
    HeaderComponent,
  },

  computed: {
    token() {
      var t = localStorage.getItem("admintokenofeducodiv");
      if (t) {
        return true;
      } else {
        return false;
      }
    },
    is_loading() {
      return this.$store.state.is_loading;
    },
  },
};
</script>
