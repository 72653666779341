import {
  createRouter,
  createWebHistory
} from 'vue-router'
import AdminView from '../views/HomeView.vue'
import CourseDetailsView from "../views/CourseDetailsView.vue"
import CreateTopicView from "../views/CreateTopicView.vue"

import UpdateTopicView from "../views/UpdateTopicView.vue"

const routes = [
  {
    path: '/webadmin/topic/:course/:category',
    name: 'topic',
    component: CreateTopicView
  },
  {
    path: '/webadmin/topic/:pk/update',
    name: 'update_topic',
    component: UpdateTopicView
  },
  {
    path: '/webadmin/course/:pk',
    name: 'course_datails',
    component: CourseDetailsView
  },
  {
    path: '/webadmin',
    name: 'home',
    component: AdminView
  },
  {
    path: '/',
    name: 'home_test',
    component: AdminView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router