<template>
  <div class="site-body" v-if="course">
    <header>
      <h1>Course : {{ course.course.name }}</h1>

      <h4>{{ get_email }}</h4>
    </header>
    <div class="course-details">
      <div>
        <br />
        <h1 class="widget-title topic-title">
          <!-- Course : {{ course.course.name }} -->
        </h1>
        <div
          v-for="(item, index) in course.topics"
          :key="index"
          class="topic-category"
        >
          <div class="topic-category-title">
            <h3>{{ item.sub_category }}</h3>
            <button
              class="btn btn-outline-primary"
              @click="navigate_create_topic(course.course.id, item.pk)"
            >
              Create New Topic
            </button>
          </div>
          <div
            v-for="(inner_item, inner_index) in item.sub_list"
            :key="inner_index"
          >
            <ul class="list-group">
              <li
                class="list-group-item"
                style="color: black !important"
                @click="navigate_update_topic(inner_item.id)"
              >
                {{ inner_item.name }}
              </li>
            </ul>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      course: null,
    };
  },
  methods: {
    navigate_create_topic(course_pk, topic_category_pk) {
      this.$router.push({
        path: `/webadmin/topic/${course_pk}/${topic_category_pk}`,
      });
    },

    navigate_update_topic(pk) {
      this.$router.push(`/webadmin/topic/${pk}/update`);
    },
  },
  mounted() {
    this.api_calling = true;
    // var token = localStorage.getItem("token");
    var token = this.token;
    const config = {
      headers: {
        Authorization: "Token " + token,
      },
    };
    var pk = this.$route.params.pk;

    var url = `${process.env.VUE_APP_BASE_URL}/course/${pk}`;
    this.$store.commit("update_is_loading", true);
    axios
      .get(url, config)
      .then((response) => {
        console.log(response.data);
        this.course = response.data;
      })
      .catch((error) => {})
      .finally(() => {
        this.$store.commit("update_is_loading", false);
      });
  },
  computed: {
    token() {
      var t = localStorage.getItem("admintokenofeducodiv");
      return t;
    },
    get_email() {
      return localStorage.getItem("admintokenofeducodivusername");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.course-details {
  padding: 1rem;
}

.topic-category {
  background: #eff4f7;
  padding: 1rem;
}

.topic-category-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
}

.topic-category-title button {
  padding: 0.3rem;
}
</style>