<template>
  <div class="site-body">
    <header>
      <h1>Create Topic</h1>
      <h4>{{ get_email }}</h4>
    </header>
    <div class="topic-create-form" v-if="course">
      <h3>Course : {{ course.course.name }}</h3>
      <p>Topic Category : {{ course.topic.name }}</p>
      <form>
        <label for="name">
          Topic Name
          <input type="text" placeholder="Topic Name" v-model="name" />
        </label>
        <p>{{ name_status }}</p>
        <label for="image">
          Image
          <input type="file" placeholder="Image" id="topic_image" />
        </label>
        <label for="meta_description"> Meta Description </label>
        <br />
        <textarea id="meta_description" v-model="meta_description"></textarea>
        <p>{{ content_status }}</p>
        <br />
        <label for="">Content</label>
        <br />
        <ckeditor
          :editor="editor"
          v-model="content"
          :config="editorConfig"
        ></ckeditor>
      </form>
      <div class="submit-container">
        <button class="btn btn-primary" @click="submit_topic">submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from "./ck/build/ckeditor";
export default {
  data() {
    return {
      course: null,
      name: "",
      name_status: "",
      meta_description: "",
      image: "",
      content: "",
      content_status: "",
      editor: ClassicEditor,
      editorConfig: {
        ckfinder: {
          uploadUrl: `${process.env.VUE_APP_BASE_URL}/account/upload`,
        },
      },
    };
  },
  methods: {
    submit_topic() {
      this.api_calling = true;
      var token = this.token;
      const config = {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "multipart/form-data",
        },
      };
      this.name_status = "";
      this.content_status = "";
      var url = `${process.env.VUE_APP_BASE_URL}/course/topic/create`;
      this.$store.commit("update_is_loading", true);
      var course = this.$route.params.course;
      var topic_category = this.$route.params.category;
      var image = document.getElementById("topic_image").files[0];

      if (this.name == null || this.name == "") {
        this.name_status = "Required field";
        return;
      }
      if (this.content == null || this.content == "") {
        this.content_status = "Required field";
        return;
      }
      var params = new FormData();

      params.append("course", course);
      params.append("topic_category", topic_category);
      params.append("name", this.name);
      params.append("meta_description", this.meta_description);
      if (image) {
        params.append("image", image);
      }
      params.append("content", this.content);
      axios
        .post(url, params, config)
        .then((response) => {
          console.log(response.data);
          if (response.data.response == "success") {
            this.$router.push(`/webadmin/course/${this.$route.params.course}`);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("update_is_loading", false);
        });
    },
  },
  mounted() {
    var token = this.token;
    const config = {
      headers: {
        Authorization: "Token " + token,
      },
    };
    console.log(this.$route.params);
    var course = this.$route.params.course;
    var topic_category = this.$route.params.category;

    var url = `${process.env.VUE_APP_BASE_URL}/course/validation/${course}/${topic_category}`;
    this.$store.commit("update_is_loading", true);
    axios
      .get(url, config)
      .then((response) => {
        console.log(response.data);
        this.course = response.data;
      })
      .catch((error) => {})
      .finally(() => {
        this.$store.commit("update_is_loading", false);
      });
  },
  computed: {
    token() {
      var t = localStorage.getItem("admintokenofeducodiv");
      return t;
    },
    get_email() {
      return localStorage.getItem("admintokenofeducodivusername");
    },
  },
  watch: {
    content(n, o) {
      console.log(n);
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.topic-create-form {
  padding: 1rem;
}
.topic-create-form form {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.topic-create-form input {
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.submit-container {
  text-align: center;
}
</style>



<style>
.ck-editor__editable_inline {
  height: 70vh;
}
.ck-editor__top {
  position: sticky !important;
  top: 0px !important;
  background: white !important;
  z-index: 1000;
}
</style>