<template>
  <router-view />
</template>
<!-- <style src='./assets/css/parents.css'></style> -->
<!-- <style src="./assets/css/parents.css"></style> -->

<script>
import axios from "axios";
export default {
  data() {
    return {
      total_data: 0,
    };
  },
  methods: {},
  created() {
    // this.get_offers(1);
    // this.get_account_history();
  },

  computed: {
    token() {
      var t = localStorage.getItem("admintokenofeducodiv");
      if (t) {
        return t;
      }
      return this.$router.push("/webadmin");
    },
  },
};
</script>